export default {
    list: [{
            id: 'cause',
            type: 'multi',
            question: 'What’s your cause?',
            desc: 'You can pick multiple options',
            options: [{
                label: 'Find a nice and fair job 💼',
                id: 'work',
                ac: [1]
            }, {
                label: 'Find sustainable products 🧦',
                id: 'products',
                ac: [5]
            }, {
                label: 'Make impactful investments 📈',
                id: 'invest',
                ac: [4]
            }]
        }, {
            id: 'occupation',
            type: 'single',
            question: 'What do you do?',
            desc: 'Pick your main occupation.',
            options: [{
                    label: 'Studying 🎓',
                    id: 'study',
                    ac: [2]
                },
                {
                    label: 'Working 🦺',
                    id: 'work',
                    ac: [3, 6]
                },
                {
                    label: 'Unemployed 🔍',
                    id: 'vacant',
                    ac: [3, 6]
                }
            ]
        }, {
            id: 'study_details',
            question: 'What do you study?',
            desc: 'Details about your studies.',
            options: [{
                    label: '🧪 Field of study',
                    id: 'field',
                    type: 'input',
                    inputType: 'text'
                }, {
                    label: '🏫 University / School',
                    id: 'location',
                    type: 'input',
                    inputType: 'text'
                },
                {
                    label: '🎓 Expected year of graduation',
                    id: 'grad_year',
                    type: 'input',
                    inputType: 'number'
                }
            ]
        }, {
            id: 'work_details',
            type: 'multi',
            question: 'What do you want to work with?',
            desc: 'You can pick multiple options.',
            ac: [6],
            options: [{
                    label: 'Tech 🕹️',
                    id: 'tech',
                },
                {
                    label: 'HR 🤝',
                    id: 'hr',
                },
                {
                    label: 'Design 📐',
                    id: 'design',
                },
                {
                    label: 'Legal ⚖️',
                    id: 'legal',
                }, {
                    label: 'Industrial 🏭',
                    id: 'industrial',
                },
                {
                    label: 'Medical 🥼',
                    id: 'medical',
                },
                {
                    label: 'Finance 📈',
                    id: 'finance',
                },
                {
                    label: 'Marketing 🎥',
                    id: 'marketing',
                }, {
                    label: 'Education 📚',
                    id: 'finance',
                },
                {
                    label: 'Other 🔮',
                    id: 'other',
                }
            ],
        },
        {
            id: 'investment_details',
            type: 'multi',
            question: 'What areas do you usually invest in?',
            desc: 'You can pick multiple options.',
            options: [{
                    label: 'Tech 🕹️',
                    id: 'tech',
                },
                {
                    label: 'Industrial 🏭',
                    id: 'industrial',
                },
                {
                    label: 'Legal ⚖️',
                    id: 'legal',
                },
                {
                    label: 'Medical 🥼',
                    id: 'medical',
                },
                {
                    label: 'Finance 📈',
                    id: 'finance',
                },
                {
                    label: 'Marketing 🎥',
                    id: 'marketing',
                },
                {
                    label: 'Other 🔮',
                    id: 'other',
                }
            ]
        },
        {
            id: 'shopping_details',
            type: 'multi',
            question: 'What products are you interested in? ',
            desc: 'You can pick multiple options.',
            options: [{
                    label: 'Tech & Media 🕹️',
                    id: 'tech_and_media',
                }, {
                    label: 'Fashion 👔',
                    id: 'fashion',
                }, {
                    label: 'Interior 🪑',
                    id: 'interior',
                }, {
                    label: 'Personal care 💄',
                    id: 'personal_care',
                }, {
                    label: 'Sports 🏓',
                    id: 'sports',
                }, {
                    label: 'Food & Beverages 🥝',
                    id: 'food_and_beverages',
                }, {
                    label: 'Books 📚',
                    id: 'books',
                },
                {
                    label: 'Other 🔮',
                    id: 'other',
                }
            ]
        },
        {
            id: 'region',
            question: 'Any specific region you want to work in?',
            desc: 'Could be a city, country, or continent.',
            skippable: {
                label: 'No, not any specific'
            },
            options: [{
                label: '🌍 Region',
                id: 'region',
                type: 'input',
                inputType: 'text'
            }]
        },
    ]
}