import { render, staticRenderFns } from "./Tailor.vue?vue&type=template&id=039ded86&scoped=true&"
import script from "./Tailor.js?vue&type=script&lang=js&"
export * from "./Tailor.js?vue&type=script&lang=js&"
import style0 from "./Tailor.vue?vue&type=style&index=0&id=039ded86&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "039ded86",
  null
  
)

export default component.exports