import {
    UPDATE,
    db,
    ADD,
    firebase,
    GET
} from '@/firebase.js'
import Loading from '@/components/Loading/Loading.vue'


import Chapters from './Chapters.js'

export default {
    components: {
        Loading
    },
    data: function () {
        return {
            showExplanationModal: false,
            confId: null,
            referralCode: null,
            sentReferralCode: false,
            verifyEmailSent: false,
            showSignupDone: false,
            showChapters: false,
            activeChapter: 0,
            afterChapters: [],
            previousChapters: [],
            email: null,
            userId: null,
            alreadyVerified: false,
            passwordPrompt: false,
            passwordError: null,
            password: null,
            passwordResetSent: false,
            showPassword: false,
            chapters: Chapters.list,
            userVerified: false,
            userTailored: false,
            authType: null,
            loading: false
        }
    },
    methods: {
        chapterIsValid: function () {
            let chapterId = this.chapters[this.activeChapter].id
            if (chapterId === 'cause' || chapterId === 'shopping_details' || chapterId === 'occupation' || chapterId === 'investment_details' || chapterId === 'work_details') {
                return this.chapters[this.activeChapter].options.find(
                    (option) => option.selected === true
                )
            } else if (chapterId === 'study_details' || chapterId === 'region') {
                let validInputs = this.chapters[this.activeChapter].options.filter(
                    (option) => (option.val && option.val.length > 0)
                )
                return this.chapters[this.activeChapter].options.length === validInputs.length
            }
        },
        setOption: function (option) {
            if (this.chapters[this.activeChapter].type === 'single') {
                let selectedOption = this.chapters[this.activeChapter].options.find(option => option.selected === true)
                if (selectedOption) selectedOption.selected = false
            }
            option.selected = !option.selected
            this.$forceUpdate()
        },
        addCauses: function (options) {
            let causes = []
            if (options.length === 0) return
            options.forEach(option => causes.push(option.id))
            UPDATE(db.doc('users/' + this.confId), {
                causes: causes
            })
            this.addAfterChapters(options)
        },
        addOccupation: function (options) {
            if (options.length !== 1) return
            UPDATE(db.doc('users/' + this.confId), {
                occupation: options[0].id
            })
            this.addAfterChapters(options)
        },
        addAfterChapters: function (options) {
            let acOptions = options.filter(option => option.ac !== undefined)
            acOptions.forEach(option => {
                this.afterChapters = this.afterChapters.concat(option.ac)
            })
        },
        addInvestmentDetails: function (options) {
            let areas = []
            if (options.length === 0) return
            options.forEach(option => areas.push(option.id))
            UPDATE(db.doc('users/' + this.confId), {
                investment: areas
            })
        },
        addWorkDetails: function (options) {
            let areas = []
            if (options.length === 0) return
            options.forEach(option => areas.push(option.id))
            UPDATE(db.doc('users/' + this.confId), {
                work: areas
            })
        },
        addShoppingDetails: function (options) {
            let areas = []
            if (options.length === 0) return
            options.forEach(option => areas.push(option.id))
            UPDATE(db.doc('users/' + this.confId), {
                products: areas
            })
        },
        addStudyInfo: function () {
            let dbObj = {}
            let inputs = this.chapters[this.activeChapter].options
            inputs.forEach(input => {
                dbObj[input.id] = input.val
            })
            UPDATE(db.doc('users/' + this.confId), {
                study_info: dbObj
            })
        },
        addRegion: function () {
            let regionVal = this.chapters[this.activeChapter].options[0].val
            UPDATE(db.doc('users/' + this.confId), {
                region: regionVal
            })
        },
        pickAfterChapter: function () {
            if (!this.afterChapters || this.afterChapters.length === 0) {
                if (this.userVerified) return this.userTailored = true
                else return this.verifyEmailSent = true
            }
            this.activeChapter = this.afterChapters[0]
            this.afterChapters.shift()
        },
        lastChapter: function () {
            this.activeChapter = this.previousChapters[this.previousChapters.length - 1]
            this.previousChapters.pop()
        },
        nextChapter: function (skipped = false) {
            this.previousChapters.push(this.activeChapter)
            if (skipped) return this.initNextChapter()
            let chapterId = this.chapters[this.activeChapter].id
            if (chapterId === 'study_details') {
                this.addStudyInfo()
            } else if (chapterId === 'region') {
                this.addRegion()
            } else {
                let pickedOptions = this.chapters[this.activeChapter].options.filter(option => option.selected === true)
                if (chapterId === 'cause') this.addCauses(pickedOptions)
                if (chapterId === 'occupation') this.addOccupation(pickedOptions)
                if (chapterId === 'investment_details') this.addInvestmentDetails(pickedOptions)
                if (chapterId === 'work_details') this.addWorkDetails(pickedOptions)
                if (chapterId === 'shopping_details') this.addShoppingDetails(pickedOptions)
            }
            this.initNextChapter()
        },
        initNextChapter: function () {
            this.pickAfterChapter()
            document.getElementById('confirm-question-inner').scrollTo(0, 0)
        },
        addEmail: function () {
            // Only used if a user has signed up through an email we've sent them
            let col = db.collection('users')
            ADD(col, {
                email: this.email,
                verified: 'almost',
                date: new Date(),
                uid: null
            }).then(doc => {
                this.confId = doc.id
                localStorage.setItem("uid", this.confId);
            })
        },
        submitPassword: function () {
            if (this.passwordPrompt === 'login') this.signIn()
            else this.register()
        },
        register: function () {
            if (!this.password) return this.passwordError = 'Please enter a password'
            else if (this.password.length < 6) return this.passwordError = 'Password needs to be minimum 4 characters'

            firebase.auth().createUserWithEmailAndPassword(this.email, this.password).then(() => {
                this.updateUidInDb()
            }).catch(error => {
                this.passwordError = error.message
                console.log(error.message)
            });
        },
        signIn: function () {
            this.loading = true
            firebase.auth().signInWithEmailAndPassword(this.email, this.password).catch((error) => {
                this.passwordError = error.message
                console.log(error.message)
            });
        },
        googleSignIn: function () {
            this.loading = true
            let provider = new firebase.auth.GoogleAuthProvider();
            provider.addScope('https://www.googleapis.com/auth/userinfo.email');
            provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
            firebase.auth().signInWithPopup(provider).catch(function (error) {
                console.log('ERROR:' + error.message)
            });
        },
        updateUidInDb: function () {
            let user = firebase.auth().currentUser;
            if (!this.confId) this.confId = user.displayName
            else {
                user.updateProfile({
                    displayName: this.confId,
                })
            }
            UPDATE(db.collection('users').doc(this.confId), {
                uid: user.uid
            }).then(() => {
                this.handleUserThatIsLoggedIn(user)
            })
        },
        addReferralCode: function () {
            if (!this.referralCode) return
            let lowercaseCode = this.referralCode.toLowerCase()
            UPDATE(db.doc('users/' + this.confId), {
                referral: lowercaseCode
            }).then(() => {
                this.sentReferralCode = 'success'
                setTimeout(() => {
                    this.showSignupDone = true
                }, 1000)
            }).catch(() => {
                let col = db.collection('failed_referrals')
                ADD(col, {
                    user: this.confId,
                    referral: lowercaseCode
                }).then(() => {
                    this.showSignupDone = true
                })
            })
        },
        addNoReferralCode: function () {
            UPDATE(db.doc('users/' + this.confId), {
                referral: false
            })
            this.showSignupDone = true
        },
        resetPassword: function () {
            if (this.passwordResetSent) return
            firebase.auth().sendPasswordResetEmail(this.email).then(() => {
                // Email sent.
                this.passwordResetSent = true
            }).catch(error => {
                // An error happened.
                alert(error)
            });
        },
        signOut: function () {
            firebase.auth().signOut().then(function () {
                // Sign-out successful.
                console.log('Signing out user')
            })
        },
        getIdFromEmail: function () {
            return new Promise(resolve => {
                let getIdFromEmail = firebase.functions().httpsCallable('getIdFromEmail');
                getIdFromEmail({
                    email: this.email
                }).then(result => {
                    console.log(result.data)
                    resolve(result.data.id)
                });
            })
        },
        getAuthTypeFromEmail: function () {
            return new Promise(resolve => {
                let getAuthTypeFromEmail = firebase.functions().httpsCallable('getAuthTypeFromEmail');
                getAuthTypeFromEmail({
                    email: this.email
                }).then(result => {
                    resolve(result.data.authType)
                });
            })
        },
        getUserInfo: async function (userId) {
            if (!userId || userId.split(' ')[1]) userId = await this.getIdFromEmail()
            return new Promise(resolve => {
                let doc = db.doc('users/' + userId)
                console.log(userId)
                GET(doc).then(user => {
                    console.log(user.data())
                    resolve({
                        verified: user.data().verified,
                        causes: user.data().causes,
                        id: user.id
                    })
                })
            })
        },
        verifyEmail: function () {
            console.log(this.confId)
            UPDATE(db.doc('users/' + this.confId), {
                verified: true
            })
            console.log('verifying user')
            this.userVerified = true
        },
        handleUserThatIsLoggedIn: async function (user) {
            this.passwordPrompt = null
            let userInfo = await this.getUserInfo(user.displayName)
            this.confId = userInfo.id
            this.userVerified = (userInfo.verified === true)
            if (!this.userVerified && this.$route.query.csndy) {
                this.verifyEmail()
            }
            if (userInfo.causes) {
                console.log('User is already tailored')
                this.userTailored = true
                if (this.userVerified && !this.$route.query.csndy) {
                    console.log('User is verified but url is not sufficent')
                }
            } else {
                this.showChapters = true
                this.passwordPrompt = null
            }
            this.loading = false
        },
        auth: function () {
            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    // User is signed in.
                    console.log('User is signed in', user)
                    let userEmail = user.providerData[0] ? user.providerData[0].email : user.email
                    if (userEmail !== this.email) {
                        this.signOut()
                    } else {
                        console.log('User email and signed in user email is same')
                        this.confId = user.uid
                        // Check so that the user isn't creating the account as we speak
                        if (user.displayName) this.handleUserThatIsLoggedIn(user)
                    }
                    this.loading = false
                } else {
                    // No user is signed in.
                    console.log('User is not signed in')
                    let authType = await this.getAuthTypeFromEmail()
                    if (authType) this.authType = authType
                    if (authType === 'Google') {
                        this.passwordPrompt = 'login'
                    } else {
                        firebase.auth().signInWithEmailAndPassword(this.email, 'sveningvarsharaldrigvaritbättreänzlatan99999999999').then(() => true).catch((error) => {
                            if (error.code === 'auth/wrong-password') {
                                // TODO : If here then it means account already exists...
                                this.passwordPrompt = 'login'
                            }

                            if (error.code === 'auth/user-not-found') {
                                // TODO : If here then you guessed it... you can create a new account.
                                this.passwordPrompt = 'signup'
                            }
                        })
                    }
                    this.loading = false
                }
            });
        },
        checkIfUserExists: function () {
            return new Promise(resolve => {
                let getUserExistence = firebase.functions().httpsCallable('getUserExistence');
                getUserExistence({
                    email: this.email
                }).then(result => {
                    console.log(result)
                    resolve(result.data.exists)
                });
            })
        },
    },
    mounted: function () {
        this.loading = true
        this.email = this.$route.query.email
        if (this.$route.query.confId) this.confId = this.$route.query.confId
        this.auth()
    }
}